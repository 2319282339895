<template>
  <section class="explore">
    <section class="search-section">
      <form class="form-inline">
        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn search-btn" type="submit">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
          </svg>
        </button>
      </form>
    </section>
    <section class="trending">
      <div class="trending-section">
        <header class="trending-header">
          <h4 class="trending-title">#ShowYourGrow</h4>
        </header>
        <section class="trending-videos">
          <section class="trending-ports">
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
          </section>
          <section class="trending-lands">
            <GridCell :type="'land vert'" />
            <GridCell :type="'land vert'" />
            <GridCell :type="'land vert'" />
            <GridCell :type="'land vert'" />
          </section>
        </section>
      </div>
      <div class="trending-section">
        <header class="trending-header">
          <h4 class="trending-title">#ShowYourGrow</h4>
        </header>
        <section class="trending-videos">
          <section class="trending-ports">
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
          </section>
          <section class="trending-lands">
            <GridCell :type="'land vert'" />
            <GridCell :type="'land vert'" />
            <GridCell :type="'land vert'" />
            <GridCell :type="'land vert'" />
          </section>
        </section>
      </div>
      <div class="trending-section">
        <header class="trending-header">
          <h4 class="trending-title">#ShowYourGrow</h4>
        </header>
        <section class="trending-videos">
          <section class="trending-ports">
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
            <GridCell :type="'port vert'" />
          </section>
          <section class="trending-lands">
            <GridCell :type="'land vert'" />
            <GridCell :type="'land vert'" />
            <GridCell :type="'land vert'" />
            <GridCell :type="'land vert'" />
          </section>
        </section>
      </div>
    </section>
  </section>
</template>

<script>
import VertGrid from "@/components/VertGrid"
import GridCell from "@/components/GridCell"

export default {
  components: {
    VertGrid,
    GridCell
  },
}
</script>

<style scoped>
.explore {
  display: flex;
  flex-direction: column;
  position: relative;
}

.search-section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: calc(5rem + 5vh);
  top: 1%;
  position: fixed;
  z-index: 2;
}

.form-inline {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 2.2rem;
}

.trending {
  flex-grow: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  min-height: 100vh;
  width: 100%;
  margin-top: 75px;
}

.trending-videos {
  width: 80vw;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.search-btn {
  padding: 0;
  margin-left: 8px;
}

.trending-videos::-webkit-scrollbar { 
  width: 0 !important;
}

.trending-videos::-webkit-scrollbar-track {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.trending-videos::-webkit-scrollbar-track {
  background: none !important;
}

.trending-ports,
.trending-lands {
  padding: 10px;
  margin: 5px;
  display: flex;
}

.trending-header {
  width: 100%;
  text-align: left;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.trending-title {
  margin-left: 10rem;
}

.trending-section {
  margin-top: 10px;
}

@media only screen and (max-width: 550px) {
  .explore {
    padding-top: calc(3rem + 3vh);
    padding-bottom: calc(4rem + 3vh);
  }

  .form-inline {
    width: 90%;
  }

  .trending-title {
    margin-left: 25px;
  }

  .search-section {
    padding-top: calc(4rem + 5vh);
  }

  .trending {
    margin-top: 50px;
  }
}

</style>
