<template>
  <section class="v-grid">
    <div class="v-grid-ports">
      <div class="v-grid-port">
        <GridCell :type="'port vert'" />
      </div>
      <div class="v-grid-port">
        <GridCell :type="'port vert'" />
      </div>
    </div>
    <div class="v-grid-landscape">
      <GridCell :type="'land vert'" />
    </div>
  </section>
</template>

<script>
import GridCell from "./GridCell.vue"

export default {
  components: {
    GridCell,
  }
}
</script>

<style scoped>
.v-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-width: 20rem;
  min-height: 100%;
  overflow: hidden;
}

.v-grid-ports,
.v-grid-landscape {
  width: 100%;
}

.v-grid-ports {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.v-grid-port,
.v-grid-landscape {
  display: flex;
  align-items: center;
}

.v-grid-port {
  width: 50%;
}

.v-grid-landscape {
  justify-content: center;
}

.v-grid-ports div:first-child {
  justify-content: flex-end;
  margin-right: 10px;
}

.v-grid-ports div:last-child {
  justify-content: flex-start;
  margin-left: 10px;
}

</style>